/* -------------------------FOOTER ------------------------- */
.footer {
    --cui-footer-min-height: 3rem;
    --cui-footer-padding-x: 1rem;
    --cui-footer-padding-y: 0.5rem;
    --cui-footer-color: rgba(44, 56, 74, 0.95);
    --cui-footer-bg: #ebedef;

    --cui-footer-border: 1px solid var(--cui-footer-border-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    min-height: var(--cui-footer-min-height);
    padding: var(--cui-footer-padding-y) var(--cui-footer-padding-x);
    color: gray;
    background: white;
    text-decoration: none;
    border-top: var(--cui-footer-border);
    border: none;
}

/* LINK DO FOOTER */
a:-webkit-any-link {
    color: var(--cor-primary);
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
}

.footer-text {
    color: var(--cor-primary);
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.footer-text a {
    color: var(--cor-primary);
    font-size: 12px;
    padding-left: 2px;
}

.icon-ajuda {
    width: 50px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
}