* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.sidebarContainer {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
}


.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.sidebarLogo {
  font-size: 30px;
}

.sidebarBars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;

  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {

  color: #000;
  transition: all 0.5s;
}


.icon,
.link_text {
  font-size: 20px;
}

@media only screen and (max-width: 600px) {

  .d-flex {
    display: flex;
    flex-direction: column;
  }
}