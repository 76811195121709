input.inputBox {
  color: #fff;
  font-size: 13px;
  width: 180px;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.rdrDateDisplayWrapper {
  border-radius: 10px;
}

.calendarWrap {
  display: inline-flex;
  position: relative;
  align-items: center;
}

.calendarElement {
  display: inline-flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: #ccc;
  z-index: 999;
  inset: 0px 0px auto auto;
  position: absolute;
  inset: 0px 0px auto auto;
  transform: translate(15px, 46px);
  border-radius: 15px;
  box-shadow: 0px 7px 20px #00000029;
}

@media (max-width: 600px) {
  .calendarElement {
    width: 300px;
    margin: 1rem;
    display: flex;
    transform: translate(80px, 46px);
  }
}