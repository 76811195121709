#firstLogin {
    background-image: url('../../assets/images/redefine-password2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100vh;
}

.firstLoginBackground {
    display: grid;
    grid-template-rows: max-content max-content auto auto;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(0.5px);
    backdrop-filter: blur(2.5px);
    background-color: hsla(0, 62%, 34%, 0.658);
}

.firstLoginTitle {
    animation: firstLoginTitle 2s 1.5s forwards ease;
    opacity: 0%;
    height: fit-content;
    text-align: center;
    padding: 80px 0px 50px 0px;
    grid-row-start: 2;
    grid-row-end: 3;
}

.firstLoginTitle h1 {
    color: white;
}

.firstLoginTitle h2 {
    color: rgb(226, 226, 226);
}


.firstLoginForm {
    animation: firstLoginTitle 2s 2s forwards ease;
    opacity: 0%;
    align-self: center;
    display: grid;
    grid-row-start: 3;
    grid-row-end: 4;
    justify-self: center;
    width: 500px;
    height: auto;
    gap: 15px;
    padding: 50px;
    border-radius: 30px;
}

.firstLoginCheck {
    margin: 10px 0px;
    width: 95%;
    justify-self: center;
    display: flex;
}

.firstLoginCheck p {
    color: rgb(218, 218, 218);
}

.firstLoginCheck a {
    color: white;
}


.firtsLoginFormBtn {
    margin-top: 10px;
    justify-self: center;
    height: fit-content;
    width: fit-content;
    box-shadow: -10px 15px 15px rgba(0, 0, 0, 0.322);
    background-color: #a80000;
    border-radius: 50px;
    padding: 5px 100px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: rgb(211, 211, 211);
    transition: 0.3s ease;
}

.firstLoginRights {
    grid-row-start: 4;
    grid-row-end: 5;
    opacity: 0%;
    animation: slideRights 1s 3s ease forwards;
    justify-self: center;
    margin: auto auto 10px auto;
}

.firstLoginRights a {
    text-decoration: dashed;
    color: white;
    font-weight: 800;
    font-size: 18px;
}

@keyframes firstLoginTitle {
    100% {
        opacity: 100%;
    }
}

@media screen and (max-width: 600px) {
    .firstLoginTitle {
        padding: 80px 20px 20px;
    }

    .firstLoginForm {
        width: 100%;
    }

    .firtsLoginFormBtn {
        padding: 5px 50px;
    }
}

@media screen and (max-width: 425px) {
    .firstLoginForm {
        padding: 30px 20px;
    }
}