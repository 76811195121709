html:not([dir=rtl]) .form-select-lg {
    padding-left: 0.65rem;
    padding-top: 19px;
}

.form-select {
    border-radius: 10px;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
    height: calc(2.5rem + 2px);
    min-height: calc(3rem + 2px);
    width: 100%;
    line-height: 1.25;
}

.form-select {
    border: 1px solid var(--cor-secondary);
}

.form-floating>label {
    position: absolute;
    top: 0;
    width: 100%;
    font-size: 13px;
    padding: 0.65rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
    opacity: 3;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    font-size: 17px;
    color: var(--cor-secondary);
}

.form-control {
    border-color: var(--cor-secondary);
    color: var(--cor-secondary);
    border-radius: 10px;
    font-size: 15px;
}

.form-check-input:checked {
    background-color: var(--cor-primary);
    border-color: var(--cui-form-check-input-checked-border-color, #c50e0e);
}

.form-check-input {
    flex: 1 1 15px;
}

.form-control {
    color: var(--cor-secondary);
    font-weight: 600;
}

.form-label {
    color: var(--cor-secondary);
    background-color: none;
}

.col-form-label {
    color: var(--cor-secondary);
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
    color: var(--cor-secondary);
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
    background: transparent;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    background-color: #ffffff00;
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
    height: 0.5em;
}

html:not([dir=rtl]) .form-select {
    padding: 1.375rem 0.25rem 0.375rem 0.75rem;
}

.titulo-filtro {
    color: #c50e0e;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.padding-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.form-floating {
    position: relative;
    width: 100%;
}