/*------------------------- DASHBOARD -------------------------*/

.card-body {
  flex: 1 1 auto;
  padding: 5px 20px;
  color: var(--cui-card-color);
}

.card {
  border-color: transparent;
  --cui-card-cap-bg: transparent;
  --cui-card-border-width: 0px;
}

.card-4 {
  border: solid 2px #1BE177;
}


.header-brand {
  color: var(--cor-cinza-b);
  font-size: 16px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}


/* ITENS DO ALERTA DE NÃO CONFORMIDADES */

/* ABERTAS */
html:not([dir=rtl]) .border-start-warning {
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px #FCCE44;
}

/* ATRASADAS */
html:not([dir=rtl]) .border-start-danger {
  /* border-left-color: #e55353 !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px #ff3939;
}

/* AVALIAÇÃO DE EFICACIA */
html:not([dir=rtl]) .border-start-info {
  /* border-left-color: #39f !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px #39f;

}

/* CONCLUIDAS */
html:not([dir=rtl]) .border-start-success {
  /* border-left-color: #2eb85c !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
  border: solid 2px #58CF19;
}

/* COR DOS NUMEROS DOS CARDS */
#efficiency {
  color: var(--cor-azul-a);
}

#open {
  color: var(--cor-laranja);
}

#delayed {
  color: var(--cor-vermelho);
}

#completed {
  color: var(--cor-verde-a);
}

/*-------------------------  RESPONSIVIDADE ------------------------- */
@media (max-width: 1052px) {


  .header-brand {
    color: var(--cor-cinza-b);
    font-size: 16px;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    height: 45px;
  }

  .d-flex.docs-highlight {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .container-body>* {
    display: block;
  }

  .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}

/* AJUSTA O CARD 3 QUANDO PRECISA FICAR 2 EM CIMA E 2 EM BAIXO */
@media (max-width: 870px) {
  .card-3 {
    padding-left: 0;
  }
}

/* AJUSTA TODOS OS OUTROS CARD QUANDO PRECISA FICAR UM BAIXO DO OUTRO */
@media (max-width: 5750px) {
  .card-1 {
    padding-left: 0;
  }

  .card-2 {
    padding-left: 0;
  }

  .card-3 {
    padding-left: 0;
  }

  .card-4 {
    padding-left: 0;
    border: none;
  }
}

/* CORREÇÃO DA SIDEBAR DESAPARECER QUANDO ESTÁ EM 768PX */
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(0 * var(--cui-sidebar-width));
  }
}

/* ------------------------- DASHBOARD------------------------- */


input {
  flex: 1 1 100px;
  /* border: solid 1px var(--cor-cinza-b); */
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 15px;
  color: var(--cor-primary);
  outline: none;
  font-size: 14px;
}

html:not([dir=rtl]) .header-brand {
  margin-right: 0px;
}

.container-body {
  margin-bottom: 1.5rem !important;
  box-shadow: 0px 0px;
}


.mb-6 {
  width: 300px;
  height: 300px;
}

/* TEXTO DO CARD ANALISE DE NAO CONFORMIDADES */
.text-analises-nao-conformidades {
  font-size: 15px;
  white-space: nowrap;
  --cui-text-opacity: 1;
}

.margin-6px {
  margin-bottom: 6px;
  margin-top: 6px;
}

.cabecalho {
  margin-bottom: 6px;
  margin-top: 6px;
}

/* COR DO BACKGROUND */
.bg-light {
  --cui-bg-opacity: 0;
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}


#IconCalendar {
  height: 50px;
}

.item-card {
  height: 80px;
  display: flex;
  align-items: center;
}

/* ITENS DO ANALISE DE NÃO CONFORMIDADES */
/* ABERTAS */
html:not([dir=rtl]) .border-start-warning {
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px #E7B43F;
  padding-left: 0;
}

/* ATRASADAS */
html:not([dir=rtl]) .border-start-danger {
  /* border-left-color: #e55353 !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px var(--cor-vermelho);
}

/* AVALIAÇÃO DE EFICACIA */
html:not([dir=rtl]) .border-start-info {
  /* border-left-color: #39f !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border: solid 2px var(--cor-azul-a);
}

/* CONCLUIDAS */
html:not([dir=rtl]) .border-start-success {
  /* border-left-color: #2eb85c !important; */
  flex: 1 1 180px;
  width: 100%;
  margin: 10px 10px 5px 5px;
  border-radius: 15px;
  padding: 12px 12px 5px 12px;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
  border: solid 2px var(--cor-verde-a);
}

html:not([dir=rtl]) .border-start-success {
  border-left-color: var(--cor-verde-a) !important;
}

/* COR DOS NUMEROS DOS CARDS */
#eficacia {
  color: #39f;
}

#abertas {
  color: #FCCE44;
}

#atrasadas {
  color: #e55353;
}

#concluidas {
  color: #2eb85c;
}

.card-header {
  padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  margin-bottom: 0;
  margin-top: 5px;
  background-color: var(--cui-card-cap-bg);
  border-bottom: var(--cui-card-border-width) solid var(--cui-card-border-color);
}