@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

* {
  padding: 0;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #ACACAC;
  border: hidden;
  box-sizing: border-box;
}

li {
  list-style: none;
}

:root {
  --cor-primary: #c11f1f;
  --cor-secondary: #701010;
  --cor-azul-a: #2A75FF;
  --cor-azul-b: #5f8fe6;
  --cor-verde-a: #1be177;
  --cor-verde-b: #009c41;
  --cor-cinza-a: #838383;
  --cor-cinza-b: #515151;
  --cor-cinza-c: #e4e4e4;
  --cor-laranja: #e7b43f;
  --cor-vermelho: #e65f5f;
  --cor-amarelo: #f7ac00;
}

/*-------------classes globais------------*/


.select-item {
  flex: 1 1 200px;
  margin-bottom: 10px;
}

.align-space-between {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.titulo-filtro {
  color: #c50e0e;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.btn-ghost-dark {
  --cui-btn-color: #fff;
  font-weight: 600;
  text-align: unset;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-ghost-dark:hover {
  --cui-btn-color: #c63434;
  background-color: #cf0808b1;
  border: none;
  border-radius: 5px;
}

.align-button-red {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

/**css view no permission***/

.no-permission-view {
  display: flex;
  height: 80vh;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.no-permission-view p {
  color: var(--cor-cinza-b);
  font-size: 22px;
}

.no-permission-view span {
  color: var(--cor-azul-a);
  font-size: 26px;
}

/*****/
@media (max-width: 420px) {
  .pagination-sm {
    --cui-pagination-padding-x: 0.4rem;
    --cui-pagination-padding-y: 0.25rem;
    --cui-pagination-font-size: 0.875rem;
    --cui-pagination-border-radius: 0.25rem;
  }
}

/* COR DE FUNDO DO BACKGROUND */
.bg-light {
  --cui-bg-opacity: 0;
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}

.cont-flex {
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
}


.dropdown-item {
  font-weight: 600;
  color: var(--cor-primary);
  cursor: pointer;
}

.text-wrapper {
  word-wrap: break-word;
}

/*Color */

.color-primary {
  color: var(--cor-secondary);
}

.color-cinza-b {
  color: var(--cor-cinza-b);
}

/*Item*/
/*Text*/

.text-redefine {
  padding: 40px 50px;
  margin-top: 20px;
}

@media (max-width: 420px) {
  .text-redefine {
    padding: 10px 30px;
    margin-top: 20px;
  }
}

.text-redefine h2 {
  color: var(--cor-primary);
  font-size: 30px;
  font-weight: 600;
}

.text-redefine p,
.text-redefine pre {
  color: var(--cor-secondary);
  font-size: 16px;
  font-weight: 600;
}

.text-redefine h6 {
  color: var(--cor-azul-a);
  font-size: 16px;
  font-weight: 600;
}

/*Header*/

.header-bottom {
  margin-bottom: 0px;
  width: 200px;
}

.header.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
  box-shadow: 0 2px 2px 0 #fff;
}


.scroll-bottom {
  position: absolute;
  float: right;
  top: 60px;
  right: 70px;
  margin: 0px 10px;
}


@media (max-width: 1050px) {
  .scroll-bottom {
    top: 5px;
  }
}

@media (max-width: 500px) {

  .header-bottom {
    width: 100%;
  }

  .scroll-bottom {
    position: absolute;
    float: right;
    top: 55px;
    right: -0px;
    margin: 0px 10px;
  }

  .header-bottom {
    margin-bottom: 35px;
  }
}


*::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  /* Largura da barra de rolagem */
  background-color: #efefef;
  /* Cor de fundo da barra de rolagem */
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  background-color: #c11f1fd5;
  border-radius: 20px;
  /* Cor do pomo da barra de rolagem */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #9c0d0d98;
  border-radius: 20px;
  /* Cor do pomo da barra de rolagem ao passar o mouse */
}

.customNavItem {
  padding-bottom: 5px;
}

/*Align action plan icon*/

.align-action-plan-icon label {
  margin: 0 !important;
}

.align-action-plan-icon {
  display: flex;
  gap: .5rem;
  height: calc(100% - 5px);
}

.align-edit {
  display: flex;
  justify-content: center;
}

li.nav-item.dropdown.icon-user::marker {
  color: transparent;
}

.w-100 {
  width: 100%;
}

.cont-items-align {
  display: flex;
  gap: 5px;
}

@media (max-width: 500px) {
  .cont-items-align {
    flex-direction: column
  }
}


.title-card-blue {
  color: var(--cor-azul-a);
  font-size: 15px;
}


.switch-label {
  color: var(--cor-cinza-a);
  /* cor padrão */
}

.switch-label.active {
  color: var(--cor-primary);
  /* cor quando ativado */
}

/* ProgressSteps*/
.progress-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 440px;
}

.step {
  flex: 1;
  text-align: center;
  padding: 17px;
  font-weight: bold;
  border-radius: 50px;
  border: solid 2px var(--cor-azul-a);
  height: 65px;
  width: 60px;
  color: var(--cor-azul-a);
  font-size: 18px;
}

.step.active {
  background-color: var(--cor-azul-a);
  color: #fff;
}

.progress-line {
  flex: 1;
  height: 3px;
  width: 20px;
  background-color: var(--cor-azul-a);
  transition: background-color 0.3s ease;
}

.progress-line.active {
  background-color: var(--cor-azul-a);
}

.progress-center {
  display: flex;
  justify-content: space-around;
}

/**/

.titulo-tab {
  font-size: 19px;
  color: var(--cor-primary);
  font-weight: bold;
}

.align-item-right {
  display: flex;
  justify-content: flex-end;
}

.align-info-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-red {
  color: var(--cor-primary);
}

.check-align {
  display: flex;
  align-items: center;
  gap: 5px;
}

.check-align p,
.check-align pre {
  margin: 5px 0px 0px 0px;
}

.align-menu {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 12px 10px;
}

.text-menu {
  color: var(--cor-primary);
  font-weight: 600;
  font-size: 18px;
  margin: auto 10px;
}

.input-cadastro {
  width: 100%;
  height: 10vh;
  font-size: 14px;
  overflow-y: scroll;
  border: solid 1px var(--cor-secondary);
  color: var(--cor-secondary);
  outline: none;
  padding: 5px 10px 0 10px;
  border-radius: 15px;
}


.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--cor-azul-a);
  background-color: var(--cor-cinza-c);
  border-color: var(--cor-cinza-a);
}

.cont-filtro {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  box-shadow: 0px 12px 22px #00000052;
}

.text-blue-primary {
  color: var(--cor-azul-a);
}

.titulo-pag {
  color: var(--cor-cinza-b);
  font-size: 16px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: default;
}


.tooltip.show {
  opacity: var(--cui-tooltip-opacity);
  z-index: 100000;
}

.position-notify {
  position: relative;
  top: 70px;
  right: 40px;
  width: 400px;
  height: 60vh;
  overflow-y: auto;
}

.icon-user {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 75px 15px;
  z-index: 99999;
  opacity: 0;
}

@media (max-width: 1000px) {
  .icon-user {
    opacity: 1;
  }
}

.icon-sair {
  width: 32px;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 25px 15px;
  z-index: 99999;
  opacity: 0;
}

@media (max-width: 1000px) {
  .icon-sair {
    opacity: 1;
  }
}

.title-label {
  font-size: 13px;
  color: var(--cor-secondary);
}


.align-center {
  align-items: center
}

.cabecalho {
  display: flex;
  justify-content: space-between
}

@media (max-width: 600px) {
  .cabecalho {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

/**************************************/
.align-tooltip-no-confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-edit {
  font-size: 18px;
  font-weight: 600;
  color: var(--cor-cinza-b);
}

.background-primary {
  background-color: var(--cor-primary);
  color: #fff;
}

.font-12 {
  font-size: 12px;
}

.color-2 {
  color: var(--cor-secondary);
  display: flex;
  justify-content: flex-end;
}

.title-18-primary {
  font-size: 18px;
  font-weight: 600;
  color: var(--cor-primary);
}


.line-blue {
  background-color: #6d0202;
  width: 200px;
  height: 2px;
}

.page-link {
  color: var(--cor-azul-a);
}

.color-secondary {
  color: #808080;
  font-size: 18px;
  font-weight: 600;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
  gap: 8px;
}

.justify-flex-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center;
}

.cont-flex {
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
}

@media (max-width: 600px) {
  .cont-flex {
    display: flex;
    flex-direction: column;
  }
}

.align-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 5px;
}

.red-global {
  background-color: var(--cor-vermelho);
}

body {
  overflow-x: hidden;
}

/*-------------------------  RESPONSIVIDADE ------------------------- */
@media (max-width: 1052px) {

  .body-content {
    margin-left: 50px;
    margin-right: -10px;
  }

  .d-flex.docs-highlight {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

  }

  .divCabecalho {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

  }
}

/* CORREÇÃO DA SIDEBAR DESAPARECER QUANDO ESTÁ EM 768PX */
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: calc(0 * var(--cui-sidebar-width));
  }
}



.buttonList{
  display: flex;
  overflow-x: auto;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
 -webkit-appearance: none; 
  margin: 0; 
}