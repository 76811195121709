#redefinePassword {
    background-image: url('../../assets/images/redefine-password2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100vh;
}

.redefinePasswordTitle {
    padding-bottom: 0px;
    display: grid !important;
    justify-self: center !important;
    text-align: center;
}

.redefinePasswordImg {
    margin-bottom: 30px;
    justify-self: center;
}