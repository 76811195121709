.status-aberto {
    color: #e7b43f;
}

.status-atrasado {
    color: #e55353;
}

.status-concluidas {
    color: #2eb85c;
}

.status-avaliacao-eficacia {
    color: #39f;
}

.calendar {
    width: 100%;
    max-width: 800px;
    min-height: 250px;
    margin: 0 auto;
    padding: 5px 15px;
}

@media (max-width: 420px) {
    .calendar {
        max-height: 280px;
    }

}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar-header h5 {
    font-weight: 700;
    color: #2c55a0;
}

.calendar-header button {
    background: none;
    cursor: pointer;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    color: #777;
    margin-bottom: 10px;
}

.calendar-day {
    border: 1px solid #cfcfcf;
    padding: 4px;
    text-align: center;
}

.calendar-day.empty {
    border: none;
}

.day-active {
    border: #2c55a0 2px solid;
    color: #2c55a0;
    border-radius: 20px;
    background: none;
    padding: 3px 10px;
    cursor: pointer;
}

.day-active:hover {
    background-color: #2c55a0;
    color: #fff;
}

@media (max-width: 420px) {
    .day-active {
        padding: 2px 5px;
    }
}

.info-plan p {
    color: #ACACAC;
    font-size: 19px;
    font-weight: 600;
}

.weekdays {
    display: none;
}

.calendar-day {
    border: none;
}

@media (max-width: 600px) {
    .calendar-grid {
        grid-template-columns: repeat(7, 1fr);
        gap: 0px;
    }

    .card-body {
        padding: 5px 8px;
    }

    .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        font-weight: bold;
        color: #777;
        margin-bottom: 10px;
    }
}