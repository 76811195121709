.align-message {
    display: flex;
    justify-content: space-around;
}

.message-warning {
    left: 1px;
    z-index: 9999;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(45deg, #f7edcf, #f3da8f);
    box-shadow: 5px 5px 30px 7px rgba(255, 255, 255, 0.25), -5px -5px 30px 7px #e9d597b2;
}

.message-danger {
    left: 1px;
    z-index: 9999;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(45deg, #f3bebe, #e77171);
    box-shadow: 5px 5px 30px 7px rgba(224, 224, 224, 0.25), -5px -5px 30px 7px #e5535359;
}

.message-success {
    left: 1px;
    z-index: 9999;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(45deg, #dafae5, #8bf3aee3);
    box-shadow: 5px 5px 30px 7px rgba(224, 224, 224, 0.25), -5px -5px 30px 7px rgba(154, 224, 166, 0.308);
}

.position {
    position: fixed;
    top: 70px;
    width: 40%;
    z-index: 999999;
    transition: 0.3s;
}

@media (max-width: 420px) {
    .position {
        width: 75%;
    }
}

.message-item {
    width: 100%;
    text-align: center;
}

.message {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
}

.message-item {
    width: 100%;
    text-align: center;
}

.message {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
}


/*message cookie*/

.cookie-message {
    position: fixed;
    bottom: 0;
    left: 1px;
    width: 100%;
    z-index: 9999;
}

.alert-info {
    --cui-alert-color: #1f5c99;
    --cui-alert-bg: #d6ebff;
    --cui-alert-border-color: #c2e0ff;
    margin: 30px;
}

.check {
    font-size: 14px;
    color: #0052cc;
    margin-left: 5px;
    background-color: transparent;
}