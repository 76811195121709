.divStuffingContent {
  display: flex;
  gap: 15px;
  align-items: center;
}

.divStuffingContent .inputs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.divStuffingContent .buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.extraCostsLIContainer > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collectionOrderLI > div button {
 margin-bottom: 1rem;
}

.extraCostsLIContainer > div p {
  color: #701010;
  white-space: initial;
  word-break: keep-all;
}

.extraCostsLIContainer > p {
  white-space: initial;
}

@media (max-width: 768px) {
  .divStuffingContent {
    flex-direction: column;
    align-items: initial;
  }
  .divStuffingContent .buttons {
    flex-direction: row;
  }

  .divStuffingContent > .inputs > div {
    flex-direction: column;
  }

  .collectionOrderLI p {
    color: #701010;
    white-space: initial;
    word-break: keep-all;
  }

  .collectionOrderLI > div {
    flex-direction: column;
  }

  .extraCostsLIContainer > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .extraCostsLICost > div{
    flex-direction: column;
  }
}
