.sidebar-nav .nav-link {
  display: block;
  flex: 1 1;
  align-items: center;
  padding: var(--cui-sidebar-nav-link-padding-y) var(--cui-sidebar-nav-link-padding-x);
  color: var(--cui-sidebar-nav-link-color);
  text-decoration: none;
  white-space: nowrap;
  background: var(--cui-sidebar-nav-link-bg);
  border: var(--cui-sidebar-nav-link-border);
  border-radius: var(--cui-sidebar-nav-link-border-radius);
  transition: background 0.15s ease, color 0.15s ease;
  padding: 15px 15px 10px;
}

.sidebar-nav .nav-link:hover {
  background-color: transparent;
}

.sidebar-nav .nav-link.active {
  background-color: #da0000a3;
  border-radius: 0px;
}

.sidebar-nav .nav-group.show .nav-group-toggle {
  color: #fff;
}

ul.nav-group-items li {
  color: var(--cor-primary);
  cursor: pointer;
  margin-block: 15px;
}

ul.nav-group-items li a.nav-link {
  padding-block: 15px;
}

.sidebar-narrow-unfoldable:not(:hover),
.sidebar-narrow {
  z-index: 1031;
  flex: 0 0 var(--cui-sidebar-narrow-width);
  width: var(--cui-sidebar-narrow-width);
  overflow: visible;
}

/* CORES DA SIDE BAR */
.CSidebarBrand {
  background-color: #2f353b;
}

.background-color-white {
  background-color: #2f353b;
}

/* COR QUANDO PASSA O MOUSE EM CIMA */
.sidebar-nav .nav-link:hover {
  color: #fff;
}

/* COR DOS TITULOS */
.sidebar-nav .nav-title {
  color: #acacac;
  padding: 5px 20px;
  margin-top: 0;
}

.sidebarDiv {
  overflow-x: hidden;
}

.sidebarContainer {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  overflow-x: hidden;
  transition: all 0.5s;
  background-color: #2f353b;
  border-radius: 0px 0px 0px 0px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.sidebarLogo {
  font-size: 30px;
}

.sidebarBars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;

  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  color: #000;
  transition: all 0.5s;
}

.icon,
.link_text {
  font-size: 20px;
}

/* DIVISOR DO MENU LATERAL */
.navbar-text {
  padding: var(--cui-sidebar-nav-title-padding-y);
  margin-top: var(--cui-sidebar-nav-title-margin-top);
  font-size: 80%;
  font-weight: 700;
  color: #d6d6d6;
  text-transform: uppercase;
  transition: height 0.15s, margin 0.15s;
}

.sidebar-brand-full {
  margin-right: 65px;
}

.menuSidebar {
  margin-right: 5px;

  text-align: right;

  align-items: center;
  justify-content: center;
  padding-top: 4px;
}

.iconSidebar {
  height: 26px;
  width: 26px;
  margin-left: 5px;
  margin-right: 20px;
}

.iconSidebar:hover {
  color: var(--cor-azul-b);
}

.iconSidebar24 {
  height: 24px;
  width: 24px;
  margin-left: 5px;
  margin-right: 20px;
}

.top-20 {
  position: relative;
  top: 155px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
}

.sidebar-nav .nav-link {
  color: #fff;
  display: flex;
}

.sidebar-nav {
  overflow-x: hidden;
  margin-top: 20px;
}

[data-simplebar] {
  position: relative;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

element.style {
  position: fixed;
  inset: 0px auto auto 0px;
  transform: translate3d(50px, 24px, 0px);
}

li {
  white-space: nowrap;
}

/* ocultar divisores de menu */
.sidebar-narrow-unfoldable:not(:hover) .d-narrow-none,
.sidebar-narrow .d-narrow-none,
.sidebar-narrow-unfoldable:not(:hover) .nav-label,
.sidebar-narrow .nav-label,
.sidebar-narrow-unfoldable:not(:hover) .navbar-text,
.sidebar-narrow .navbar-text,
.sidebar-narrow-unfoldable:not(:hover) .nav-group-items,
.sidebar-narrow .nav-group-items,
.sidebar-narrow-unfoldable:not(:hover) .sidebar-footer,
.sidebar-narrow .sidebar-footer,
.sidebar-narrow-unfoldable:not(:hover) .sidebar-form,
.sidebar-narrow .sidebar-form,
.sidebar-narrow-unfoldable:not(:hover) .sidebar-header,
.sidebar-narrow .sidebar-header {
  height: 0 !important;
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 600px) {
  .sidebar-brand-full {
    margin-right: 65px;
  }

  .top-20 {
    position: relative;
    top: 140px;
  }

  .sidebar-nav .nav-link {
    padding: 20px 15px 25px;
  }
}

@media (max-width: 768px) {
  .sidebar.sidebar-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
  }

  .dropdown-menu {
    width: 80%;
  }

  li {
    white-space: wrap;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    height: 40%;
    padding: var(--cui-dropdown-item-padding-y) var(--cui-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--cui-dropdown-link-color);
    text-align: inherit;

    text-decoration: none;
    white-space: pre-line;
    background-color: transparent;
    border: 0;
  }


  .sidebar-narrow-unfoldable:not(:hover) .nav-title{
    display: none;
  }

}
