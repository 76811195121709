#forgetPassword {
    background-image: url('../../assets/images/redefine-password2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100vh;
}

.forgetPasswordTitle {
    display: grid;
    text-align: left;
    justify-self: center;
    align-self: center;
}

.forgetPasswordImg {
    background-color: #740000;
    justify-self: left;
    border-radius: 40%;
    padding: 15px;
    width: fit-content;
    display: grid;
    justify-content: center;
}

.forgetPasswordTitle h1 {
    padding-top: 30px;
    color: white;
    font-size: 35px;
}

.forgetPasswordTitle p {
    font-size: 20px;
    color: rgb(216, 216, 216);
}

.forgetPasswordForm {
    margin-top: 50px;
    /*     border: 1px solid hsla(0, 100%, 35%, 0.479);
 */
    gap: 20px;
    width: fit-content;
    height: fit-content;
    justify-self: center;
    display: grid;
    padding: 50px 30px 20px 30px;
    border-radius: 20px;
    /*     -webkit-backdrop-filter: blur(10.5px);
    backdrop-filter: blur(5.5px);
    background-color: hsla(0, 100%, 29%, 0.5); */
}

.redefinePasswordBtn {
    display: grid;
    width: 100%;
    justify-self: center;
    border-radius: 30px;
    padding: 5px 50px;
    letter-spacing: -.5px;
    font-size: 20px;
    box-shadow: -10px 15px 15px rgba(0, 0, 0, 0.322);
    background-color: #a80000;
    transition: 0.4s ease;
    color: rgba(255, 255, 255, 0.61);
}

.redefinePasswordBtn:hover {
    color: white;
}

.received {
    margin: 40px auto 0px auto;
    text-align: center;
    cursor: pointer;
    color: white;
    opacity: 60%;
    transition: 0.4s ease;
}

.received:hover {
    opacity: 100%;
}


@media screen and (max-width: 768px) {
    .forgetPasswordTitle h1 {
        font-size: 28px;
    }

    .forgetPasswordForm {
        margin: 0px;
    }
}

@media screen and (max-width: 425px) {
    .forgetPasswordTitle h1 {
        font-size: 25px;
    }

    .forgetPasswordForm {
        margin: 0px;
    }
}