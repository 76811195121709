.content-tabela {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.content-tabela th {
  color: var(--cor-primary);
  font-weight: 700;
  font-size: 17px;
}

.content-tabela tr {
  padding: 4px 6px;
}

.content-tabela td {
  color: #808080;
  font-size: 16px;
  height: 52px;
  padding: 8px;
}

.button-table-red {
  padding: 4px;
  max-width: 50px;
  background-color: #ff3939;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
}

.button-table-red:hover {
  background-color: #ff3939b2;
}

.button-table-yellow {
  padding: 4px;
  max-width: 50px;
  background-color: #f7ac00;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
}

.button-table-yellow:hover {
  background-color: #f7ad00b2;
}

.button-table-blue {
  padding: 4px;
  max-width: 95px;
  background-color: #0052cc;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  border: none;
}

.button-table-blue:hover {
  background-color: #0052ccb2;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.form-control {
  height: 33px;
}

.flex-table {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.list-table {
  border-top: 2px solid #c2c2c299;
  padding: 6px;
  margin-top: 4px;
}

select.select-table {
  width: 110px;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid var(--cor-secondary);
  border-radius: 10px;
  font-size: 15px;
  outline: none;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--cui-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
}

.table-buttons {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .content-tabela td {
    color: #808080 var(--cor-cinza-b);
    font-size: 12px;
    padding: 8px;
  }

  .content-tabela th {
    color: var(--cor-primary);
    font-size: 14px;
    font-weight: 700;
  }
}

@media (max-width: 800px) {
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --cui-table-accent-bg: #ffffff00;
    color: var(--cui-table-striped-color);
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-width: 0;
  }

  .table > :not(caption) > * > * {
    padding: 0.5rem 1rem;
  }

  .table-buttons {
    flex-direction: column-reverse;
    gap: 10px;
  }
}
