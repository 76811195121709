.loader1 {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #db3434;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader2 {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #db3434;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader3 {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #db3434;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader4 {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #db3434;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/*Big-x*/
.spinner-overlay-big-x {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

/*Big*/
.spinner-overlay-big {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

/*Small-x*/

.spinner-overlay-small-x {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

/*Small*/

.spinner-overlay-small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
}

.spinner-overlay-small .line {
    width: 40px;
    height: 5px;
    border-radius: 5px;
    margin: 3px 0;
    position: relative;
}