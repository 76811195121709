.select-global select {
    width: 100%;
    display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--cor-secondary);
    background: transparent;
}

.select-global {
    flex: 1 1 40px;
    width: 100%;
    height: 38px;
    padding: 8px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--cor-secondary);
    border: solid 1px var(--cor-secondary);
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 14px;
}