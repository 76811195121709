/* Global */
@import "./ComponentsStyles/App.css";

/* Componentes */
@import "./ComponentsStyles/Buttons.css";
@import "./ComponentsStyles/Components.css";
@import "./ComponentsStyles/Calendar.css";
@import "./ComponentsStyles/Card.css";
@import "./ComponentsStyles/DateRangeComp.css";
@import "./ComponentsStyles/Footer.css";
@import "./ComponentsStyles/Form.css";
@import "./ComponentsStyles/Header.css";
@import "./ComponentsStyles/Message.css";
@import "./ComponentsStyles/Modal.css";
@import "./ComponentsStyles/Notify.css";
@import "./ComponentsStyles/Select.css";
@import "./ComponentsStyles/Sidebar.css";
@import "./ComponentsStyles/Spinner.css";
@import "./ComponentsStyles/Tab.css";
@import "./ComponentsStyles/Table.css";

/* Views */
@import "./ViewsStyles/Login.css";
@import "./ViewsStyles/FirstLogin.css";
@import "./ViewsStyles/AdministrativePanel.css";
@import "./ViewsStyles/Dashboard.css";
@import "./ViewsStyles/forgetPassword.css";
@import "./ViewsStyles/RedefinePasword.css";
@import "./ViewsStyles/ServiceOrder.css";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

* {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}