.card {
    --cui-card-border-color: none;
}

.card {
    box-shadow: 0 1px 1px 0 #fff;
}

.card-shadow {
    box-shadow: 0px 7px 20px #00000029;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}